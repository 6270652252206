import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// import "@babel/polyfill";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import moment from 'moment';
moment.locale('es');
window.moment = moment;
moment.defaultFormat = "DD/MM/YYYY HH:mm";

import common from './common';
Vue.mixin(common)

import VueCardFormat from 'vue-credit-card-validation';
Vue.use(VueCardFormat);

import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';
Vue.use(Croppa);

//axios.defaults.baseURL = "http://127.0.0.1:8000/api";
axios.defaults.baseURL = "https://api.larebaja.pe/api";

Vue.config.productionTip = false;

require('./store/subscriber')

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["Authenticate/authenticated"]) {
      if (store.getters["Authenticate/permissions"].includes(to.name)) {
        next();
      } else {
        next({
          name: "Home",
        });
      }
    } else {
      next({
        name: "HomePage",
      });
    }
  } else {
    // if (store.getters["Authenticate/authenticated"]) {
    //   next({
    //     name: "Home",
    //   });
    // } else {
    //   next();
    // }
    next();
  }
});

store.dispatch("Authenticate/attempt", localStorage.getItem("token")).then(() => {
  const app = new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
