export default {
    namespaced: true,
    state: {
        overlay: false,
    },
    getters: {
        overlay(state) {
            return state.overlay
        }
    },
    mutations: {
        SET_OVERLAY(state) {
            state.overlay = !state.overlay
        }
    },
}