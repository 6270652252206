import axios from 'axios';
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { mapActions } from "vuex";

export default {
    computed: {
        ...mapGetters("Authenticate", ["authenticated", "roles", "roleSelected", "permissions", "quickAccess", "company"]),
        ...mapGetters("Authenticate", ["authenticated", "roles", "roleSelected", "permissions", "quickAccess"]),
        ...mapGetters("Color", ["colorBackground"]),

        currentCompany() {
            if (this.company) {
                return this.company;
            }
            return null;
        },
        currentUser() {
            if (this.authenticated) {
                return this.authenticated;
            }
            return null;
        },
        listQuickAccess() {
            if (this.quickAccess) {
                if (this.quickAccess.length > 0) {
                    return this.quickAccess;
                }
                return [];
            }
            return [];
        },
        listRoles() {
            if (this.roles) {
                if (this.roles.length > 0) {
                    return this.roles;
                }
                return [];
            }
            return [];
        },
        currentRole() {
            if (this.roleSelected) {
                return this.roleSelected;
            }
            return null;
        },
        listPermissions() {
            if (this.permissions) {
                if (this.permissions.length > 0) {
                    return this.permissions;
                }
                return [];
            }
            return [];
        },
        currenColor() {
            if (this.colorBackground) {
                return this.colorBackground
            }
            return null
        }
    },
    methods: {
        ...mapMutations("Color", ["SET_COLOR"]),
        ...mapActions("Authenticate", ["attempt"]),

        async callGet(method, url, dataObj) {
            try {
                return await axios({
                    method: method,
                    url: url,
                    params: dataObj
                });
            } catch (e) {
                return e.response
            }
        },
        async callSet(method, url, dataObj, header) {
            try {
                return await axios({
                    method: method,
                    url: url,
                    data: dataObj,
                    header
                });
            } catch (e) {
                return e.response
            }
        },
        async callReports(method, url, dataObj, header) {
            try {
                return await axios({
                    method: method,
                    url: url,
                    data: dataObj,
                    responseType: header
                });
            } catch (e) {
                return e.response
            }
        },
        // warn, error, success, etc
        notification(type, text, title = null) {
            this.$notify({
                type: type,
                text: text,
                title: title
            });
        },
        goBack() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
        },
        updateState() {
            this.attempt(localStorage.getItem('token'))
        },
        updateColor(value) {
            this.SET_COLOR(value)
        }
    }
}