import axios from 'axios';

export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
        quickAccess: null,
        permissions: null,
        roleSelected: null,
        roles: null,
        company: null
    },
    getters: {
        authenticated(state) {
            return state.token && state.user;
        },
        user(state) {
            return state.user
        },
        permissions(state) {
            return state.permissions
        },
        roleSelected(state) {
            return state.roleSelected
        },
        roles(state) {
            return state.roles
        },
        quickAccess(state) {
            return state.quickAccess
        },
        company(state) {
            return state.company;
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_PERMISSION(state, permission) {
            state.permissions = permission;
        },
        SET_ROL_SELECTED(state, roleSelected) {
            state.roleSelected = roleSelected;
        },
        SET_ROLES(state, roles) {
            state.roles = roles;
        },
        SET_QUICKACCESS(state, quickAccess) {
            state.quickAccess = quickAccess;
        },
        SET_COMPANY(state, company) {
            state.company = company;
        },
    },
    actions: {
        async signIn({ dispatch }, credentials) {
            let response = await axios.post('/auth/login', credentials);
            return dispatch('attempt', response.data.access_token);
        },
        async attempt({ commit, state }, token) {
            if (token) {
                commit('SET_TOKEN', token);
            }

            if (!state.token) {
                return
            }

            try {
                let response = await axios.get('/auth/me')
                commit('SET_USER', response.data)
            } catch (error) {
                commit('SET_TOKEN', null);
                commit('SET_USER', null);
                commit('SET_QUICKACCESS', null);
                commit('SET_PERMISSION', null);
                commit('SET_ROL_SELECTED', null);
                commit('SET_ROLES', null);
            }

            try {
                let uri = '/segurity/usuario/rolesPermissions'
                let rolSelected = localStorage.getItem('rolSelected')

                let response = await axios.get(uri, {
                    params: {
                        'rolSelected': rolSelected
                    }
                });

                commit('SET_QUICKACCESS', response.data.quickAccess.sort())
                commit('SET_PERMISSION', response.data.allPermissions.sort())
                commit('SET_ROL_SELECTED', response.data.roleSelected)
                commit('SET_ROLES', response.data.roles)

            } catch (error) {
                commit('SET_TOKEN', null);
                commit('SET_USER', null);
                commit('SET_QUICKACCESS', null);
                commit('SET_PERMISSION', null);
                commit('SET_ROL_SELECTED', null);
                commit('SET_ROLES', null);
            }

            try {
                let response = await axios.get('/configuration/company/show/' + 1);
                commit('SET_COMPANY', response.data)
            } catch (error) {
                commit('SET_TOKEN', null);
                commit('SET_USER', null);
                commit('SET_QUICKACCESS', null);
                commit('SET_PERMISSION', null);
                commit('SET_ROL_SELECTED', null);
                commit('SET_ROLES', null);
            }
        },
        signOut({ commit }) {
            return axios.post('/auth/logout').then(() => {
                commit('SET_TOKEN', null);
                commit('SET_USER', null);
                commit('SET_QUICKACCESS', null);
                commit('SET_PERMISSION', null);
                commit('SET_ROL_SELECTED', null);
                commit('SET_ROLES', null);
            })
        },
    }
}