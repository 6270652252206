import Vue from 'vue'
import Vuex from 'vuex'
import Authenticate from './auth'
import Menu from './menu'
import Loading from './loading'
import Color from './color'
import Chat from './chat'
import Task from './task'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Authenticate,
    Menu,
    Loading,
    Color,
    Chat,
    Task
  }
})
