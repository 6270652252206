export default {
    namespaced: true,
    state: {
        colorBackground: 'primary',
    },
    getters: {
        colorBackground(state) {
            return state.colorBackground
        }
    },
    mutations: {
        SET_COLOR(state, value) {
            state.colorBackground = value
        }
    },
}