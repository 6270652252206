export default {
    namespaced: true,
    state: {
        drawer: true,
    },
    getters: {
        drawer(state) {
            return state.drawer
        }
    },
    mutations: {
        SET_DRAWER(state) {
            state.drawer = !state.drawer
        }
    },
}