<template>
  <!-- :dark="!!(isDark === 'dark')" -->
  <v-app id="inspire">
    <!-- Notifications -->
    <notifications position="bottom right" />

    <!-- Preloader -->
    <v-overlay :value="$store.state.Loading.overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>

    <!-- Navigation -->
    <router-view />
  </v-app>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "App",
    computed: {
      isDark() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
    },
    methods: {
      ...mapState("Loading", ["overlay"]),
    },
  };
</script>

<style>
</style>