import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const Login = () => import("@//components/Auth/Login.vue");
const Register = () => import("@//components/Auth/Register.vue");
const ForgotPassword = () => import("@//components/Auth/ForgotPassword.vue");
const ResetPassword = () => import("@//components/Auth/ResetPassword.vue");

const Home = () => import("@//views/Home.vue");
const NotFound = () => import("@/views/NotFound.vue");

const Log = () => import("@/components/Panel/Log/index.vue");
const Permission_index = () => import("@/components/Panel/Permission/index.vue");
const Role_index = () => import("@/components/Panel/Role/index.vue");
const User_index = () => import("@/components/Panel/User/index.vue");
const User_create = () => import("@/components/Panel/User/create.vue");
const User_edit = () => import("@/components/Panel/User/edit.vue");
const User_permission = () => import("@/components/Panel/User/permission.vue");
const User_profile = () => import("@/components/Panel/User/profile.vue");
const Menu_index = () => import("@/components/Panel/Menu/index.vue");
const Menu_view = () => import("@/components/Panel/Menu/view.vue");
const Company_createOrUpdate = () => import("@/components/Panel/Company/createOrUpdate.vue");
const TaxRate_index = () => import("@/components/Panel/TaxRate/index.vue");
const WaitTime_index = () => import("@/components/Panel/WaitTime/index.vue");
const OrderStatus_index = () => import("@/components/Panel/OrderStatus/index.vue");
const SubStatus_view = () => import("@/components/Panel/OrderStatus/view.vue");
const Marca_index = () => import("@/components/Panel/Marca/index.vue");
const Modelo_index = () => import("@/components/Panel/Modelo/index.vue");
const PaymentPlatform_index = () => import("@/components/Panel/PaymentPlatform/index.vue");
const Delivery_index = () => import("@/components/Panel/Delivery/index.vue");
const Country_index = () => import("@/components/Panel/Country/index.vue");
const City_index = () => import("@/components/Panel/City/index.vue");
const District_index = () => import("@/components/Panel/District/index.vue");
const BreakingNew_index = () => import("@/components/Panel/BreakingNew/index.vue");
const PromoCode_index = () => import("@/components/Panel/PromoCode/index.vue");
const Category_index = () => import("@/components/Panel/Category/index.vue");
const Product_index = () => import("@/components/Panel/Product/index.vue");
const Tag_index = () => import("@/components/Panel/Tag/index.vue");
const Customer_index = () => import("@/components/Panel/Customer/index.vue");
const Driver_index = () => import("@/components/Panel/Driver/index.vue");
const Order_index = () => import("@/components/Panel/Order/index.vue");
const Order_create = () => import("@/components/Panel/Order/create.vue");
const Order_create_copy = () => import("@/components/Panel/Order/createCopy.vue");
const Order_edit = () => import("@/components/Panel/Order/edit.vue");
const PaymentPercentage_index = () => import("@/components/Panel/PaymentPercentage/index.vue");
const Payment_index = () => import("@/components/Panel/Payment/index.vue");
const Route_index = () => import("@/components/Panel/Route/index.vue");
const Day_index = () => import("@/components/Panel/Day/index.vue");
const Hour_index = () => import("@/components/Panel/Hour/index.vue");
const DayDistrictHour_index = () => import("@/components/Panel/DayDistrictHour/index.vue");
const Task_index = () => import("@/components/Panel/Task/index.vue");
const ArticleSize_index = () => import("@/components/Panel/ArticleSize/index.vue");
const TypeArticle_index = () => import("@/components/Panel/TypeArticle/index.vue");
const TypeVehicle_index = () => import("@/components/Panel/TypeVehicle/index.vue");
const Factor_index = () => import("@/components/Panel/Factor/index.vue");
const Version_createOrUpdate = () => import("@/components/Panel/Version/createOrUpdate.vue");
const Sunat_index = () => import("@/components/Panel/Sunat/index.vue");
const Motorized_index = () => import("@/components/Panel/Motorized/index.vue");
const SunatReporte_index = () => import("@/components/Panel/SunatReporte/index.vue");
const Task_admin = () => import("@/components/Panel/TaskAdmin/index.vue");
const Pipeline_index = () => import("@/components/Panel/Pipeline/index.vue");
const FrequentQuestions_index = () => import("@/components/Panel/FrequentQuestion/index.vue");
const ClaimBook_index = () => import("@/components/Panel/ClaimBook/index.vue");
const OrderControl_index = () => import("@/components/Panel/OrderControl/index.vue");
const OrderControl_create = () => import("@/components/Panel/OrderControl/create.vue");
const OrderControl_edit = () => import("@/components/Panel/OrderControl/edit.vue");
const OrderControl_detailAssign = () => import("@/components/Panel/OrderControl/detailAssign.vue");
const OrderControl_detailAssignAdmin = () => import("@/components/Panel/OrderControl/detailAssignAdmin.vue");

const Report_Client_admin = () => import("@/components/Panel/Report/client.vue");
const Report_PreventaTotal_admin = () => import("@/components/Panel/Report/preventaTotal.vue");
const Report_VentaMensualBaseCreado_admin = () => import("@/components/Panel/Report/ventaMensualBaseCreado.vue");
const Report_VentaMensualBaseCobro_admin = () => import("@/components/Panel/Report/ventaMensualBaseCobro.vue");
const Report_PorcentajeClientePrimeraCompra_admin = () => import("@/components/Panel/Report/porcentajeClientePrimeraCompra.vue");
const Report_TasaPedidoAnulado_admin = () => import("@/components/Panel/Report/tasaPedidoAnulado.vue");
const Report_ValorVentaPromedio_admin = () => import("@/components/Panel/Report/valorVentaPromedio.vue");
const Report_PreventaPrendaTotal_admin = () => import("@/components/Panel/Report/preventaPrendaTotal.vue");
const Report_PrendaTotal_admin = () => import("@/components/Panel/Report/prendaTotal.vue");
const Report_PrendaTotalFR_admin = () => import("@/components/Panel/Report/prendaTotalFR.vue");
const Report_PreventaTotalFR_admin = () => import("@/components/Panel/Report/preventaTotalFR.vue");
const Report_ClientPagado_admin = () => import("@/components/Panel/Report/clientPagado.vue");
const Report_HistorialFechaOrden_admin = () => import("@/components/Panel/Report/historialFechaOrden.vue");
const Report_HistorialEstadoOrden_admin = () => import("@/components/Panel/Report/historialEstadoOrden.vue");
const Report_PrendaTotalFE_admin = () => import("@/components/Panel/Report/prendaTotalFE.vue");
const Report_OrdenFechaRE_admin = () => import("@/components/Panel/Report/ordenFechaRE.vue");
const Report_ClientCustom_admin = () => import("@/components/Panel/Report/clientCustom.vue");

const Report_Client = () => import("@/components/Panel/ReportFree/client.vue");
const Report_PreventaTotal = () => import("@/components/Panel/ReportFree/preventaTotal.vue");
const Report_VentaMensualBaseCreado = () => import("@/components/Panel/ReportFree/ventaMensualBaseCreado.vue");
const Report_VentaMensualBaseCobro = () => import("@/components/Panel/ReportFree/ventaMensualBaseCobro.vue");
const Report_PorcentajeClientePrimeraCompra = () => import("@/components/Panel/ReportFree/porcentajeClientePrimeraCompra.vue");
const Report_TasaPedidoAnulado = () => import("@/components/Panel/ReportFree/tasaPedidoAnulado.vue");
const Report_ValorVentaPromedio = () => import("@/components/Panel/ReportFree/valorVentaPromedio.vue");
const Report_PreventaPrendaTotal = () => import("@/components/Panel/ReportFree/preventaPrendaTotal.vue");
const Report_PrendaTotal = () => import("@/components/Panel/ReportFree/prendaTotal.vue");
const Report_PrendaTotalFR = () => import("@/components/Panel/ReportFree/prendaTotalFR.vue");
const Report_PreventaTotalFR = () => import("@/components/Panel/ReportFree/preventaTotalFR.vue");
const Report_ClientPagado = () => import("@/components/Panel/ReportFree/clientPagado.vue");
const Report_HistorialFechaOrden = () => import("@/components/Panel/ReportFree/historialFechaOrden.vue");
const Report_HistorialEstadoOrden = () => import("@/components/Panel/ReportFree/historialEstadoOrden.vue");
const Report_PrendaTotalFE = () => import("@/components/Panel/ReportFree/prendaTotalFE.vue");
const Report_OrdenFechaRE = () => import("@/components/Panel/ReportFree/ordenFechaRE.vue");
const Report_ClientCustom = () => import("@/components/Panel/ReportFree/clientCustom.vue");

const ReportPrueba = () => import("@/components/Panel/ReportPrueba/client.vue");

const ConfigNotification_createOrUpdate = () => import("@/components/Panel/ConfigNotification/createOrUpdate.vue");

const Ecommerce = () => import("@//components/Ecommerce/index.vue");
const HomePage = () => import("@//components/Ecommerce/HomePage/index.vue");


const routes = [
  // {
  //   path: "/",
  //   component: Ecommerce,
  //   children: [
  //     {
  //       path: "/",
  //       name: "HomePage",
  //       component: HomePage,
  //     },
  //   ]
  // },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/registrar",
    name: "Register",
    component: Register,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    props: true
  },
  {
    path: "/panel",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/panel/log",
        name: "log.index",
        component: Log,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/permiso",
        name: "permiso.index",
        component: Permission_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/rol",
        name: "rol.index",
        component: Role_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/usuario",
        name: "usuario.index",
        component: User_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/usuario/crear",
        name: "usuario.crear",
        component: User_create,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/panel/usuario/editar',
        name: 'usuario.editar',
        component: User_edit,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: '/panel/usuario/permiso',
        name: 'usuario.permiso',
        component: User_permission,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: '/panel/usuario/perfil',
        name: 'usuario.perfil',
        component: User_profile,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: '/panel/menu',
        name: 'menu.index',
        component: Menu_index,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: '/panel/menu/ver',
        name: 'menu.ver',
        component: Menu_view,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: '/panel/compania',
        name: 'compania.createOrUpdate',
        component: Company_createOrUpdate,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/impuesto",
        name: "impuesto.index",
        component: TaxRate_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/tiempo-espera",
        name: "tiempo.espera.index",
        component: WaitTime_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/estado",
        name: "estado.index",
        component: OrderStatus_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/panel/estado/ver',
        name: 'estado.ver',
        component: SubStatus_view,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/marca",
        name: "marca.index",
        component: Marca_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/modelo",
        name: "modelo.index",
        component: Modelo_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/plataforma-pago",
        name: "payment.platform.index",
        component: PaymentPlatform_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/delivery",
        name: "delivery.index",
        component: Delivery_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/pais",
        name: "pais.index",
        component: Country_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/ciudad",
        name: "ciudad.index",
        component: City_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/distrito",
        name: "distrito.index",
        component: District_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/noticia",
        name: "noticia.index",
        component: BreakingNew_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/promocion",
        name: "promocion.index",
        component: PromoCode_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/categoria",
        name: "categoria.index",
        component: Category_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/producto",
        name: "producto.index",
        component: Product_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/etiqueta",
        name: "etiqueta.index",
        component: Tag_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/cliente",
        name: "cliente.index",
        component: Customer_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/driver",
        name: "driver.index",
        component: Driver_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/orden",
        name: "orden.index",
        component: Order_index,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/orden/crear",
        name: "orden.crear",
        component: Order_create,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/orden/duplicar",
        name: "orden.duplicar",
        component: Order_create_copy,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: '/panel/orden/editar',
        name: 'orden.editar',
        component: Order_edit,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/porcentaje-pago",
        name: "porcentaje.pago.index",
        component: PaymentPercentage_index,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/pago",
        name: "pago.index",
        component: Payment_index,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/organizar-ruta",
        name: "organizar.ruta.index",
        component: Route_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/dia",
        name: "dia.index",
        component: Day_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/hora",
        name: "hora.index",
        component: Hour_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/configurar-ruta",
        name: "configurar.ruta.index",
        component: DayDistrictHour_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/tarea",
        name: "tarea.index",
        component: Task_index,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/tamanio-articulo",
        name: "tamanio.articulo.index",
        component: ArticleSize_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/tipo-articulo",
        name: "tipo.articulo.index",
        component: TypeArticle_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/tipo-vehiculo",
        name: "tipo.vehiculo.index",
        component: TypeVehicle_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/factor",
        name: "factor.index",
        component: Factor_index,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/panel/version',
        name: 'version.createOrUpdate',
        component: Version_createOrUpdate,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/panel/sunat",
        name: "sunat.index",
        component: Sunat_index,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/motorizado",
        name: "motorizado.index",
        component: Motorized_index,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/sunat-reporte",
        name: "reporte.sunat",
        component: SunatReporte_index,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/tarea",
        name: "tarea.administrar",
        component: Task_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/cliente",
        name: "reporte.admin.clientes",
        component: Report_Client_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/preventa-totales",
        name: "reporte.admin.preventas.totales",
        component: Report_PreventaTotal_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/venta-mensual-base-creado",
        name: "reporte.admin.ventas.mensuales.base.creado",
        component: Report_VentaMensualBaseCreado_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/venta-mensual-base-cobro",
        name: "reporte.admin.ventas.mensuales.base.cobro",
        component: Report_VentaMensualBaseCobro_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/porcentaje-cliente-primera-compra",
        name: "reporte.admin.porcentaje.clientes",
        component: Report_PorcentajeClientePrimeraCompra_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/tasa-pedido-anulado",
        name: "reporte.admin.tasa.pedido.anulados",
        component: Report_TasaPedidoAnulado_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/valor-venta-promedio",
        name: "reporte.admin.valor.venta.promedio",
        component: Report_ValorVentaPromedio_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/preventa-prendas-totales",
        name: "reporte.admin.preventas.prendas.totales",
        component: Report_PreventaPrendaTotal_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/prendas-totales",
        name: "reporte.admin.prendas.total",
        component: Report_PrendaTotal_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/prendas-totales-fecha-recojo",
        name: "reporte.admin.prendas.total.fr",
        component: Report_PrendaTotalFR_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/preventa-totales-fecha-recojo",
        name: "reporte.admin.preventas.totales.fr",
        component: Report_PreventaTotalFR_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/cliente-orden-invertido",
        name: "reporte.admin.cliente.orden.invertido",
        component: Report_ClientPagado_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/historial-fecha-orden",
        name: "reporte.admin.historial.fecha.orden",
        component: Report_HistorialFechaOrden_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/historial-estado-orden",
        name: "reporte.admin.historial.estado.orden",
        component: Report_HistorialEstadoOrden_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/prendas-totales-fecha-entrega",
        name: "reporte.admin.prendas.total.fe",
        component: Report_PrendaTotalFE_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/orden-fecha-rec-entre",
        name: "reporte.admin.orden.fecha.recojo.entrega",
        component: Report_OrdenFechaRE_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/admin/reportes/cliente-custom",
        name: "reporte.admin.clientes.custom",
        component: Report_ClientCustom_admin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/configurador-notificacion",
        name: "configurador.notificacion",
        component: ConfigNotification_createOrUpdate,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/embudo",
        name: "embudo.index",
        component: Pipeline_index,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/pregunta-frecuente",
        name: "preguntas.frecuentes.index",
        component: FrequentQuestions_index,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/libro-reclamaciones",
        name: "libro.reclamaciones.index",
        component: ClaimBook_index,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/orden-control",
        name: "orden.control.index",
        component: OrderControl_index,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/orden-control/crear",
        name: "orden.control.crear",
        component: OrderControl_create,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/orden-control/editar",
        name: "orden.control.editar",
        component: OrderControl_edit,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/orden-control/asignado",
        name: "orden.control.asignado",
        component: OrderControl_detailAssign,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/orden-control/asignado/admin",
        name: "orden.control.asignado.admin",
        component: OrderControl_detailAssignAdmin,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/cliente",
        name: "reporte.clientes",
        component: Report_Client,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/preventa-totales",
        name: "reporte.preventas.totales",
        component: Report_PreventaTotal,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/venta-mensual-base-creado",
        name: "reporte.ventas.mensuales.base.creado",
        component: Report_VentaMensualBaseCreado,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/venta-mensual-base-cobro",
        name: "reporte.ventas.mensuales.base.cobro",
        component: Report_VentaMensualBaseCobro,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/porcentaje-cliente-primera-compra",
        name: "reporte.porcentaje.clientes",
        component: Report_PorcentajeClientePrimeraCompra,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/tasa-pedido-anulado",
        name: "reporte.tasa.pedido.anulados",
        component: Report_TasaPedidoAnulado,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/valor-venta-promedio",
        name: "reporte.valor.venta.promedio",
        component: Report_ValorVentaPromedio,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/preventa-prendas-totales",
        name: "reporte.preventas.prendas.totales",
        component: Report_PreventaPrendaTotal,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/prendas-totales",
        name: "reporte.prendas.total",
        component: Report_PrendaTotal,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/prendas-totales-fecha-recojo",
        name: "reporte.prendas.total.fr",
        component: Report_PrendaTotalFR,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/preventa-totales-fecha-recojo",
        name: "reporte.preventas.totales.fr",
        component: Report_PreventaTotalFR,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/cliente-orden-invertido",
        name: "reporte.cliente.orden.invertido",
        component: Report_ClientPagado,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/historial-fecha-orden",
        name: "reporte.historial.fecha.orden",
        component: Report_HistorialFechaOrden,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/historial-estado-orden",
        name: "reporte.historial.estado.orden",
        component: Report_HistorialEstadoOrden,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/prendas-totales-fecha-entrega",
        name: "reporte.prendas.total.fe",
        component: Report_PrendaTotalFE,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/orden-fecha-rec-entre",
        name: "reporte.orden.fecha.recojo.entrega",
        component: Report_OrdenFechaRE,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes/cliente-custom",
        name: "reporte.clientes.custom",
        component: Report_ClientCustom,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
      {
        path: "/panel/reportes-prueba/cliente",
        name: "reporte.prueba.cliente",
        component: ReportPrueba,
        meta: {
          requiresAuth: true,
        },
        props: true
      },
    ]
  },
  {
    path: '*',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
