export default {
    namespaced: true,
    state: {
        dialogChat: false,
        allMessageNotRead: 0
    },
    getters: {
        dialogChat(state) {
            return state.dialogChat
        },
        allMessageNotRead(state) {
            return state.allMessageNotRead
        }
    },
    mutations: {
        SET_OPEN_OR_CLOSE_DIALOG(state) {
            state.dialogChat = !state.dialogChat
        },
        SET_ALL_MESSAGE_NOT_READ(state, overlay) {
            state.allMessageNotRead = overlay
        }
    },
}