export default {
    namespaced: true,
    state: {
        tasksNotification: 0,
    },
    getters: {
        tasksNotification(state) {
            return state.tasksNotification
        }
    },
    mutations: {
        SET_TASK_NOTIFICATION(state, overlay) {
            state.tasksNotification = overlay
        }
    },
}