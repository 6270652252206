import store from '../store'
import axios from 'axios';

store.subscribe((mutation) => {
    switch (mutation.type) {
        case 'Authenticate/SET_TOKEN':
            // si esta autenticado
            if (mutation.payload) {
                // enviar en la cabecera el token y guardar en el storage para futuras acciones
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + mutation.payload

                localStorage.setItem('token', mutation.payload)
            } else {
                // de lo contrario borrar la cabecera y borrar el storge
                axios.defaults.headers.common['Authorization'] = null;
                //borrar el token, rol
                localStorage.removeItem('token')
                localStorage.removeItem('rolSelected')
            }
            break;

        default:
            break;
    }
})